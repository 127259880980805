import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import PageTitle from "../../system/page_title/page_title";
import { Box, HStack, VStack } from "native-base";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  TimePicker,
  Switch,
} from "antd";
import { navigateBack } from "../../../../helpers/navigator";
import { useTranslation } from "react-i18next";
import Content from "../admin_article/admin_article_form/content";
import SpreadNews from "../admin_article/admin_article_form/spread_news";
import ShortStory from "../admin_article/admin_article_form/short_story";
import Source from "../admin_article/admin_article_form/source";
import VForm from "../../system/ui/antd_form";
import HistoryTitle from "./history_title";
import HistorySubtitle from "./history_subtitle";
import {
  dynamic_request,
  get_all_languages_query,
  get_fact_check_history_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { retrieveItem } from "../../../../helpers/functions";
import Category from "../admin_article/admin_article_form/categories";
import PartyTag from "../admin_article/admin_article_form/party_tags";
import AntiPartyTag from "../admin_article/admin_article_form/anti_party_tag";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const FactCheckHistoryDetails = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const role = retrieveItem("role");
  console.log("role", role);

  const id = location?.props_state?.selected_items?.record?.id;
  console.log("location", location, id);
  const [content, set_content] = useState({ html: null, json: null });
  const [spread_news, set_spread_news] = useState({ html: null, json: null });
  const [short_story, set_short_story] = useState({ html: null, json: null });
  const [source, set_source] = useState({ html: null, json: null });

  const fact_check_history = useDynamicSelector("get_fact_check_history");
  const { items: system_languages } = useDynamicSelector(
    "get_all_system_languages"
  );
  console.log("fact_check_history", fact_check_history);

  const ratings = [
    {
      id: "true",
      name: "True",
    },
    {
      id: "fake",
      name: "Fake",
    },
    {
      id: "mix",
      name: "Mix",
    },
    {
      id: "sarcasm",
      name: "Sarcasm",
    },
    {
      id: "misleading",
      name: "Misleading",
    },
    {
      id: "false",
      name: "False",
    },
  ];

  const handle_modal_close = () => {
    navigateBack();
  };

  useEffect(() => {
    if (id) {
      get_fact_check_history_details();
      get_all_languages();
    }
  }, [id]);

  useEffect(() => {
    let formatted_date = null;
    let formatted_time = null;
    if (fact_check_history?.published_date_time) {
      const moment_object = dayjs(fact_check_history?.published_date_time).tz(
        "Asia/Kolkata"
      );
      formatted_date = moment_object
        .subtract(5, "hour")
        .subtract(30, "minute")
        .format("YYYY-MM-DD");
      formatted_time = moment_object;
    }
    if (fact_check_history) {
      form.setFieldsValue({
        title: fact_check_history?.title ? fact_check_history?.title : "",
        subtitle: fact_check_history?.subtitle
          ? fact_check_history?.subtitle
          : "",
        language_id: fact_check_history?.language_id
          ? fact_check_history?.language_id
          : "",
        published_date: formatted_date
          ? dayjs(formatted_date, "YYYY-MM-DD")
          : null,
        published_time: formatted_time
          ? dayjs(formatted_time, "HH:mm:ssZ")
          : null,
        is_fact: fact_check_history?.is_fact ? fact_check_history?.is_fact : "",
        perma_link: fact_check_history?.perma_link
          ? fact_check_history?.perma_link
          : "",
        category_tags: fact_check_history?.category_tags?.map((x) => x.id),
        party_tags: fact_check_history?.party_tags?.map((x) => x.id),
        anti_party_tags: fact_check_history?.anti_party_tags?.map((x) => x.id),
        content: fact_check_history?.content
          ? set_content({ html: fact_check_history?.content })
          : set_content({ html: null, json: null }),
        spread_news: fact_check_history?.spread_news
          ? set_spread_news({ html: fact_check_history?.spread_news })
          : set_spread_news({ html: null, json: null }),
        short_story: fact_check_history?.short_story
          ? set_short_story({ html: fact_check_history?.short_story })
          : set_short_story({ html: null, json: null }),
        source: fact_check_history?.source
          ? set_source({ html: fact_check_history?.source })
          : set_source({ html: null, json: null }),
        thumbnail_img: fact_check_history?.thumbnail_img
          ? [
              {
                url: fact_check_history?.thumbnail_img,
                status: "uploaded",
                uid: "rc-upload-1686120521566-5",
                name: "Image",
              },
            ]
          : [],
      });
    }
  }, [fact_check_history]);

  const get_fact_check_history_details = () => {
    let key = [{ key: "get_fact_check_history", loading: true }];
    let query = get_fact_check_history_query;
    let variables = {
      id: id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_languages = () => {
    let key = [{ key: "get_all_system_languages", loading: true }];
    let query = get_all_languages_query;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };
  return (
    <>
      <PageTitle title={"Fact Check History Details"} />
      <VStack padding={"30px"}>
        <HStack justifyContent={"flex-end"} space={"10px"}>
          <Button onClick={handle_modal_close} danger>
            {t("back")}
          </Button>
        </HStack>
        <Form id="fact_check_form" form={form} layout="vertical">
          <HistoryTitle />
          <HistorySubtitle />
          <HStack space={"10px"}>
            <Box flex={1}>
              <Form.Item label="Language" name={"language_id"}>
                <Select disabled>
                  {system_languages?.map((option, index) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Box>

            {role === "Admin" && (
              <Box flex={2}>
                <HStack space={"10px"}>
                  <Form.Item
                    name="published_date"
                    label={"Published Date and Time"}
                  >
                    <DatePicker disabled format={"YYYY-MM-DD"} />
                  </Form.Item>
                  <Form.Item name="published_time" label={" "}>
                    <TimePicker disabled use24Hours format={"HH:mm:ss"} />
                  </Form.Item>
                  <Form.Item name="notify_post" label={"Send Notification"}>
                    <Switch disabled />
                  </Form.Item>
                </HStack>
              </Box>
            )}
          </HStack>

          <HStack space={"10px"}>
            <Box flex={1}>
              <Category
                disabled
                language_id={fact_check_history?.language_id}
              />
            </Box>
            <Box flex={1}>
              <PartyTag
                disabled
                language_id={fact_check_history?.language_id}
              />
            </Box>
            <Box flex={1}>
              <AntiPartyTag
                disabled
                language_id={fact_check_history?.language_id}
              />
            </Box>
          </HStack>
          <Form.Item label="Rating" name={"is_fact"}>
            <Select disabled>
              {ratings?.map((option, index) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <VForm.TextBox disabled label={"Perma link"} field={"perma_link"} />
          <VForm.ImageFile
            label="Thumbnail Image"
            field={"thumbnail_img"}
            disabled
          />
          <Content
            content={content}
            set_content={set_content}
            is_disabled={true}
          />
          <SpreadNews
            content={spread_news}
            set_content={set_spread_news}
            is_disabled={true}
          />
          <ShortStory
            content={short_story}
            set_content={set_short_story}
            is_disabled={true}
          />
          <Source
            content={source}
            set_content={set_source}
            is_disabled={true}
          />
        </Form>
      </VStack>
    </>
  );
};

export default FactCheckHistoryDetails;
