import Redirector from "@views/pages/redirector";
import CustomLayout from "../layouts/custom_layout";
import YouTurnLandingPage from "../pages/youturn_landing_page";
import FactCheck from "../pages/fact_check";
import SingleArticlePage from "../pages/single_article_page";
import Articles from "../pages/articles";
import UserContribution from "../pages/user_contribution";
import PartyWise from "../pages/party_wise";
import SingleFactCheck from "../pages/single_fact_check";
import ArticlesList from "../pages/articles_list";
import SearchResults from "../pages/search_results";
import UserLayout from "../layouts/user_layout";
import AdminFactCheck from "../components/admin/admin_fact_check";
import AdminArticle from "../components/admin/admin_article";
import Category from "../pages/admin/category";
import PartyTag from "../pages/admin/party_tag";
import FactCheckDetails from "../components/admin/admin_article/admin_details";
import AdminArticleDetails from "../components/admin/admin_article/admin_article_details";
import Teammates from "../pages/admin/teammates";
import Users from "../pages/admin/users";
import Donations from "../pages/admin/donations";
import signIn from "../pages/sign_in";
import AdminLandingPage from "../pages/admin/landing_page";
import UserClaims from "../pages/admin/user_claims";
import ContactUsList from "../pages/admin/contact_us_list";
import ReportsList from "../pages/admin/reports_list";
import AccessRights from "../components/system/access_rights/access_rights";
import FactCheckList from "../pages/factcheck_list";
import RecommendedStories from "../pages/admin/recommended_stories";
import Videos from "../pages/videos";
import PrivacyPolicy from "../pages/privacy_policy";
import AdminLoginPage from "../pages/login_page";
import Comments from "../pages/admin/comments";
import CommentsDetails from "../components/admin/admin_comments/comments_details";
import ForgotPassword from "../pages/forgot_password";
import UserProfile from "../pages/user_profile";
import ResetPassword from "../pages/reset_password";
import EditUserProfile from "../pages/edit_user_profile";
import AdminArticleHistory from "../pages/admin/admin_article_history";
import AdminFactCheckHistory from "../pages/admin/admin_fact_check_history";
import UserProfileDonationList from "../pages/user_profile_donation_list";
import UserProfileList from "../pages/user_profile_list";
import UserProfileClaimList from "../pages/user_profile_claim_list";
import Reasons from "../pages/admin/reasons";
import CorrectionPolicy from "../components/about_us/correction_policy";
import PoliticalInvolvementPolicy from "../components/about_us/political_involvement_policy";
import AdminFeaturedStories from "../pages/admin/admin_featured_stories";
import Awards from "../pages/admin/awards";
import AwardsDetails from "../components/admin/awards/awards_details";
import ChangePassword from "../pages/change_password";
import FactCheckHistoryDetails from "../components/admin/admin_fact_check_history/fact_check_history_details";
import ArticleHistoryDetails from "../components/admin/admin_article_history/article_history_details";
import ContributionForm from "../components/user_contributions_form/contribution_form";
import FactCheckContribution from "../components/user_contributions_form/fact_check_contribution";
import UserArticleList from "../components/user_contribution_list/user_article_list";
import UserFactCheckList from "../components/user_contribution_list/user_fact_check_list";
import BookmarkList from "../components/user_profile/bookmark_list";
import UserProfilePendingForm from "../components/user_profile/user_profile_pending_form";
import TopContributorsPage from "../pages/user_conribution_top_contributers";
import SupportUs from "../pages/support_us";
import PageNotFound from "../components/page_not_found/page_not_found";
import AuthorDetails from "../pages/author_details";
import AuthorDetailsList from "../components/editor_details/author_details_list";
import ArticlePreview from "../pages/admin/article_preview";
import AdminFactCheckPreview from "../pages/admin/admin_fact_check_preview";
import AntiPartyTags from "../pages/admin/anti_party_tags";
import Test from "../pages/test";

export const ROUTES = {
  REDIRECTOR: "/",
  LOADINGPAGETWO: "/components",
  YOU_TURN_LANDING_PAGE: "/you-turn",
  SINGLE_ARTICLE_PAGE: "/article",
  ARTICLES: "/articles",
  USER_CONTRIBUTION: "/user-contribution",
  PARTY_WISE: "/party-wise",
  FACT_CHECK: "/fact-check",
  SINGLE_FACT_CHECK: "/factcheck",
  VIDEOS: "/videos",
  LIST_OF_ARTICLES: "/category/articles",
  SEARCH_RESULTS: "/search-results",
  ADMIN_FACT_CHECK: "/admin/fact-check",
  ADMIN_FACT_CHECK_DETAILS: "/admin/fact-check-details",
  ADMIN_ARTICLE: "/admin/articles",
  ADMIN_ARTICLE_DETAILS: "/admin/article-details",
  ADMIN_CATEGORY: "/admin/category",
  ADMIN_PARTY_TAG: "/admin/party-tag",
  ADMIN_TEAMMATES: "/admin/teammates",
  ADMIN_USER_LIST: "/admin/user-list",
  ADMIN_DONATION: "/admin/donation",
  SIGN_IN: "/sign-in",
  ADMIN_LOGIN: "/login",
  ADMIN_LANDING_PAGE: "/admin/landing-page",
  USER_CLAIM_LIST: "/admin/user-claims-list",
  LIST_OF_FACT_CHECK: "/category/fact-check",
  CONTACT_US_LIST: "/admin/contact-us-list",
  REPORT_LIST: "/admin/report-list",
  ACCESS_RIGHTS: "/access-rights",
  RECOMMENDED_STORIES: "/admin/recommended-stories",
  PRIVACY_POLICY: "/privacy-policy",
  COMMENTS: "/admin/comments",
  COMMENTS_LIST: "/admin/comment-details",
  FORGOT_PASSWORD: "/forgot-password",
  USER_PROFILE: "/profile",
  RESET_PASSWORD: "/reset-password",
  EDIT_USER_PROFILE: "/edit-user-profile",
  ARTICLE_HISTORY: "/admin/article/history",
  ARTICLE_HISTORY_DETAILS: "/admin/article/history-details",
  FACT_CHECK_HISTORY: "/admin/fact-checks/history",
  ADMIN_FACT_CHECK_HISTORY_VIEW_PAGE: "/admin/fact-checks/history-details",
  USER_PROFILE_DONATION_LIST: "/donations",
  USER_PROFILE_LIST: "/profiles",
  USER_PROFILE_CLAIM_LIST: "/claims",
  REASONS: "/admin/reasons",
  CORRECTIONS_POLICY: "/corrections-policy",
  POLITICAL_INVESTMENT_POLICY: "/political-involvement-policy",
  FEATURED_STORIES: "/admin/featured-stories",
  AWARDS: "/admin/awards",
  AWARDS_DETAILS: "/admin/awards-details",
  CHANGE_PASSWORD: "/change-password",
  USER_CONTRIBUTED_FORM: "/user-article-form",
  USER_FACT_CHECK_CONTRIBUTION_FORM: "/user-fact-check-form",
  USER_FACT_CHECK_LIST: "/user-fact-check-list",
  USER_ARTICLE_LIST: "/user-article-list",
  USER_BOOK_MARKS: "/bookmarks",
  USER_PROFILE_PENDING_FORM: "/pending-list",
  TOP_CONTRIBUTORS: "/top-contributors",
  SUPPORT_US: "/support-us",
  PAGE_NOT_FOUND: "/page-not-found",
  AUTHOR_DETAILS: "/author",
  AUTHOR_PROFILE_LIST: "/author-profile",
  ARTICLE_PREVIEW: "/admin/article-preview",
  ADMIN_FACT_CHECK_PREVIEW: "/fact-check-preview",
  ADMIN_ANTI_PARTY_TAG: "/admin/anti-party-tags",
};
const myRoutes = [
  {
    path: ROUTES.SIGN_IN,
    key: "sign_in",
    component: signIn,
    authenticate: false,
  },

  {
    path: ROUTES.ADMIN_LOGIN,
    key: "admin_login",
    component: AdminLoginPage,
    authenticate: false,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    key: "forgot_password",
    component: ForgotPassword,
    authenticate: false,
  },
  {
    path: ROUTES.CHANGE_PASSWORD,
    key: "forgot_password",
    component: ChangePassword,
    authenticate: false,
  },
  {
    path: `${ROUTES.RESET_PASSWORD}/:token?`,
    key: "forgot_password",
    component: ResetPassword,
    authenticate: false,
  },
  {
    path: `/admin/test`,
    key: "Admin fact check",
    component: Test,
    authenticate: true,
    exact: true,
  },
  {
    key: "Admin fact check",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: `${ROUTES.ADMIN_FACT_CHECK}/:value?`,
        key: "Admin fact check",
        component: AdminFactCheck,
        authenticate: true,
        exact: true,
      },
      {
        path: `${ROUTES.ADMIN_FACT_CHECK_DETAILS}/:id?`,
        key: "Admin fact check",
        component: FactCheckDetails,
        authenticate: true,
        exact: true,
      },
 
      {
        path: `${ROUTES.FACT_CHECK_HISTORY}/:id?`,
        key: "Admin fact check",
        component: AdminFactCheckHistory,
        authenticate: true,
        exact: true,
      },
      {
        path: `${ROUTES.ADMIN_FACT_CHECK_HISTORY_VIEW_PAGE}/:id?`,
        key: "Admin fact check",
        component: FactCheckHistoryDetails,
        authenticate: true,
        exact: true,
      },
      {
        path: `${ROUTES.AUTHOR_DETAILS}/:name?`,
        key: "Author",
        component: AuthorDetails,
        authenticate: false,
      },
      {
        path: ROUTES.AUTHOR_PROFILE_LIST,
        key: "author-profile-list",
        component: AuthorDetailsList,
        authenticate: false,
      },
    ],
  },

  {
    path: ROUTES.ADMIN_FACT_CHECK_PREVIEW,
    key: "Admin fact check preview",
    component: AdminFactCheckPreview,
    authenticate: false,
    exact: false,
  },

  {
    key: "Master data",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.ADMIN_CATEGORY,
        key: "Master data",
        component: Category,
        authenticate: true,
        exact: true,
      },
      {
        path: ROUTES.ADMIN_USER_LIST,
        key: "Master data",
        component: Users,
        authenticate: true,
        exact: true,
      },
      {
        path: ROUTES.REASONS,
        key: "Master data",
        component: Reasons,
        authenticate: true,
        exact: true,
      },
      {
        path: ROUTES.ADMIN_PARTY_TAG,
        key: "Master data",
        component: PartyTag,
        authenticate: true,
        exact: true,
      },
      {
        path: ROUTES.ADMIN_ANTI_PARTY_TAG,
        key: "Master data",
        component: AntiPartyTags,
        authenticate: true,
        exact: true,
      },
      {
        path: ROUTES.ADMIN_TEAMMATES,
        key: "Master data",
        component: Teammates,
        authenticate: true,
        exact: true,
      },
      {
        path: ROUTES.AWARDS,
        key: "Master data",
        component: Awards,
        authenticate: true,
        exact: true,
      },
      {
        path: `${ROUTES.AWARDS_DETAILS}/:id?`,
        key: "Master data",
        component: AwardsDetails,
        authenticate: true,
        exact: true,
      },
      {
        path: ROUTES.COMMENTS,
        key: "Master data",
        component: Comments,
        authenticate: true,
        exact: true,
      },
      {
        path: `${ROUTES.COMMENTS_LIST}/:id`,
        key: "Master data",
        component: CommentsDetails,
        authenticate: true,
        exact: true,
      },
    ],
  },
  {
    key: "Admin donation",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.ADMIN_DONATION,
        key: "Admin donation",
        component: Donations,
        authenticate: true,
        exact: true,
      },
    ],
  },

  {
    key: "Admin landing page",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: `${ROUTES.ADMIN_LANDING_PAGE}/:slot`,
        key: "Admin landing page",
        component: AdminLandingPage,
        authenticate: true,
        exact: true,
      },
    ],
  },

  {
    key: "User claims",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.USER_CLAIM_LIST,
        key: "User claims",
        component: UserClaims,
        authenticate: true,
        exact: true,
      },
    ],
  },
  {
    key: "Feedback",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.CONTACT_US_LIST,
        key: "Feedback",
        component: ContactUsList,
        authenticate: true,
        exact: true,
      },
    ],
  },
  {
    key: "Reports list",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.REPORT_LIST,
        key: "Reports list",
        component: ReportsList,
        authenticate: true,
        exact: true,
      },
    ],
  },
  {
    key: "Recommended stories",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: `${ROUTES.RECOMMENDED_STORIES}/:story`,
        key: "Recommended stories",
        component: RecommendedStories,
        authenticate: true,
        exact: true,
      },
    ],
  },
  {
    key: "Featured stories",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.FEATURED_STORIES,
        key: "Featured stories",
        component: AdminFeaturedStories,
        authenticate: true,
        exact: true,
      },
    ],
  },
  {
    key: "Access rights",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.ACCESS_RIGHTS,
        key: "Access rights",
        component: AccessRights,
        authenticate: true,
        exact: true,
      },
    ],
  },

  {
    key: "Admin article",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        // path: ROUTES.ADMIN_ARTICLE,
        path: `${ROUTES.ADMIN_ARTICLE}/:value?`,
        key: "Admin article",
        component: AdminArticle,
        authenticate: true,
        exact: true,
      },
      {
        path: `${ROUTES.ADMIN_ARTICLE_DETAILS}/:id?`,
        key: "Admin article",
        component: AdminArticleDetails,
        authenticate: true,
        exact: true,
      },

      {
        path: `${ROUTES.ARTICLE_HISTORY}/:id?`,
        key: "Admin article",
        component: AdminArticleHistory,
        authenticate: true,
        exact: true,
      },
      {
        path: `${ROUTES.ARTICLE_HISTORY_DETAILS}/:id?`,
        key: "Admin article",
        component: ArticleHistoryDetails,
        authenticate: true,
        exact: true,
      },
      
    ],
  },
  

  {
    path: `${ROUTES.ARTICLE_PREVIEW}/:id?`,
    key: "admin article preview",
    component: ArticlePreview,
    authenticate: false,
    exact: false,
  },

  {
    key: "Header Bar",
    component: CustomLayout,
    authenticate: false,
    children: [
      {
        // path:`${ROUTES.REDIRECTOR}/:session_id?`,
        path: ROUTES.REDIRECTOR,
        exact: true,
        key: "Header Bar",
        component: AdminLoginPage,
        // authenticate: false,
      },
     
    
      {
        path: ROUTES.YOU_TURN_LANDING_PAGE,
        key: "home",
        component: YouTurnLandingPage,
        authenticate: false,
      },

      

      {
        path: ROUTES.SUPPORT_US,
        key: "Support Us",
        component: SupportUs,
        authenticate: false,
      },
     
      {
        path: ROUTES.CORRECTIONS_POLICY,
        key: "corrections-policy",
        component: CorrectionPolicy,
        authenticate: false,
      },
      {
        path: ROUTES.POLITICAL_INVESTMENT_POLICY,
        key: "political-investment-policy",
        component: PoliticalInvolvementPolicy,
        authenticate: false,
      },
      {
        path: `${ROUTES.SINGLE_ARTICLE_PAGE}/:perma_link.html`,
        key: "article",
        component: SingleArticlePage,
        authenticate: false,
      },
      {
        path: ROUTES.ARTICLES,
        key: "articles",
        component: Articles,
        authenticate: false,
      },

      {
        path: ROUTES.USER_CONTRIBUTION,
        key: "contribution",
        component: UserContribution,
        authenticate: false,
      },

      {
        path: ROUTES.USER_PROFILE,
        key: "User profile",
        component: UserProfile,
        authenticate: false,
      },
      {
        path: ROUTES.PARTY_WISE,
        key: "party-wise",
        component: PartyWise,
        authenticate: false,
      },
      {
        path: ROUTES.FACT_CHECK,
        key: "Fact checks",
        component: FactCheck,
        authenticate: false,
      },
      {
        path: `${ROUTES.SINGLE_FACT_CHECK}/:perma_link.html`,
        key: "Fact checks",
        component: SingleFactCheck,
        authenticate: false,
      },
      {
        path: ROUTES.VIDEOS,
        key: "videos",
        component: Videos,
        authenticate: false,
      },
      {
        path: `${ROUTES.LIST_OF_ARTICLES}/:category?/:link?`,
        key: "articles",
        component: ArticlesList,
        authenticate: false,
      },
      // {
      //   path: `${ROUTES.LIST_OF_ARTICLES}:perma_link`,
      //   key: "articles",
      //   component: ArticlesList,
      //   authenticate: false,
      // },
      {
        path: `${ROUTES.LIST_OF_FACT_CHECK}/:category?/:link?`,
        key: "fact-check",
        component: FactCheckList,
        authenticate: false,
      },
      // {
      //   path: `${ROUTES.LIST_OF_FACT_CHECK}:perma_link`,
      //   key: "fact-check-list",
      //   component: FactCheckList,
      //   authenticate: false,
      // },
      {
        path: `${ROUTES.SEARCH_RESULTS}/:encrypted_status?`,
        key: "search",
        component: SearchResults,
        authenticate: false,
      },
      {
        path: ROUTES.PRIVACY_POLICY,
        key: "privacy-policy",
        component: PrivacyPolicy,
        authenticate: false,
      },

      {
        path: ROUTES.EDIT_USER_PROFILE,
        key: "edit-user-profile",
        component: EditUserProfile,
        authenticate: false,
      },
      {
        path: ROUTES.USER_PROFILE_DONATION_LIST,
        key: "donation History",
        component: UserProfileDonationList,
        authenticate: false,
      },
      {
        path: `${ROUTES.USER_PROFILE_LIST}/:type?`,
        key: "user-profile-list",
        component: UserProfileList,
        authenticate: false,
      },

      
      {
        path: `${ROUTES.USER_BOOK_MARKS}/:type?`,
        key: "bookmarks",
        component: BookmarkList,
        authenticate: false,
      },
      {
        path: `${ROUTES.USER_PROFILE_CLAIM_LIST}/:type?`,
        key: "claims",
        component: UserProfileClaimList,
        authenticate: false,
      },
      {
        path: ROUTES.USER_CONTRIBUTED_FORM,
        key: "contribution",
        component: ContributionForm,
        authenticate: false,
      },
      {
        path: ROUTES.USER_PROFILE_PENDING_FORM,
        key: "pending_list",
        component: UserProfilePendingForm,
        authenticate: false,
      },
      {
        path: ROUTES.USER_FACT_CHECK_CONTRIBUTION_FORM,
        key: "Fact check contribution",
        component: FactCheckContribution,
        authenticate: false,
      },
      {
        path: ROUTES.USER_FACT_CHECK_LIST,
        key: "user-fact-check-list",
        component: UserFactCheckList,
        authenticate: false,
      },
      {
        path: ROUTES.USER_ARTICLE_LIST,
        key: "user-article-list",
        component: UserArticleList,
        authenticate: false,
      },
      {
        path: ROUTES.TOP_CONTRIBUTORS,
        key: "top-contributors",
        component: TopContributorsPage,
        authenticate: false,
      },

      {
        path: ROUTES.PAGE_NOT_FOUND,
        key: "page-not-found",
        component: PageNotFound,
        authenticate: false,
      },

      // {
      //   path: `${ROUTES.LIST_OF_FACT_CHECK}/:perma_link.html`,
      //   key: "articles",
      //   component: ArticlesList,
      //   authenticate: false,
      // },
    ],
  },
];

export default myRoutes;
