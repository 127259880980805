import { Pagination, Space, Table, Tooltip } from "antd";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { dynamic_clear, useDynamicSelector } from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { HStack, Pressable } from "native-base";
import {
  get_status,
  navigateWithProps,
  retrieveItem,
} from "../../../../helpers/functions";
import { ROUTES } from "../../../routes/my_routes";
import { countFormat } from "../../../../helpers/constants";
import { navigate } from "../../../../helpers/navigator";
import { HistoryOutlined } from "@ant-design/icons";
import moment from "moment";
import { FaEye } from "react-icons/fa";

const AdminFactCheckList = (props) => {
  const {
    set_modal_title,
    set_fact_check_modal_open,
    set_selected_item,
    get_all_my_fact_checks,
    handle_close,
    set_delete_modal_visible,
    type,
    language_id,
    fact_check_type,
    get_all_user_fact_check,
    get_editors_fact_check,
    set_page_limit,
    set_page_number,
    page_limit,
    page_number,
    get_all_fact_checks,
    search_text,
    from_date,
    to_date,
    set_fact_check_type,
    default_language,
    new_default_language_id,
    set_back_button_click,
    set_default_language,
    status_option,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = retrieveItem("role");

  const {
    items: fact_check_list,
    loading: fact_check_list_loading,
    pagination: fact_check_pagination,
  } = useDynamicSelector("get_all_my_fact_check");

  const {
    items: users_fact_check_list,
    loading: user_fact_check_loading,
    pagination: user_fact_check_pagination,
  } = useDynamicSelector("get_all_previleged_user_fact_check");

  const {
    items: editors_fact_check_list,
    loading: editors_fact_check_loading,
    pagination: editors_fact_check_pagination,
  } = useDynamicSelector("get_all_editor_fact_check");

  const {
    items: all_fact_check_list,
    loading: all_fact_check_loading,
    pagination: all_fact_check_pagination,
  } = useDynamicSelector("get_all_published_fact_check");

  const { status: delete_fact_check_status, error: delete_fact_check_error } =
    useDynamicSelector("delete_fact_check");

  useEffect(() => {
    if (delete_fact_check_status === "Success") {
      toast.success("Fact Check deleted successfully");
      navigate(ROUTES.ADMIN_FACT_CHECK);
      get_all_my_fact_checks(page_limit, page_number);
      get_editors_fact_check(page_limit, page_number);
      get_all_user_fact_check(page_limit, page_number);
      get_all_fact_checks(page_limit, page_number);
      dispatch(dynamic_clear("delete_fact_check"));
    } else if (delete_fact_check_error?.message) {
      toast.error(delete_fact_check_error.message);
      dispatch(dynamic_clear("delete_fact_check"));
    }
  }, [
    delete_fact_check_status,
    delete_fact_check_error,
    page_limit,
    page_number,
    language_id,
  ]);

  useEffect(() => {
    get_all_my_fact_checks(page_limit, to_date ? 1 : page_number);
  }, [page_limit, page_number]);

  useEffect(() => {
    if (fact_check_type === "user_fact_check") {
      get_all_user_fact_check(page_limit, to_date ? 1 : page_number);
    } else if (fact_check_type === "my_fact_check") {
      get_all_my_fact_checks(page_limit, to_date ? 1 : page_number);
    } else if (fact_check_type === "editors_fact_check") {
      get_editors_fact_check(page_limit, to_date ? 1 : page_number);
    } else if (fact_check_type === "all_fact_check") {
      get_all_fact_checks(page_limit, to_date ? 1 : page_number);
    }
  }, [
    page_limit,
    page_number,
    type,
    fact_check_type,
    language_id,
    search_text,
    from_date,
    to_date,
    status_option,
  ]);

  const handle_fact_check_history = (record) => {
    navigateWithProps(`${ROUTES.FACT_CHECK_HISTORY}/${record?.id}`, {
      record: record,
      fact_check_type,
      default_language,
    });
  };

  const handle_edit_fact_check = (record) => {
    set_modal_title("edit");
    set_fact_check_modal_open(true);
    set_fact_check_type(fact_check_type);
    set_selected_item(record);
    navigateWithProps(`${ROUTES.ADMIN_FACT_CHECK_DETAILS}/${record?.id}`, {
      fact_check_type,
      default_language,
    });
    dispatch(dynamic_clear("get_fact_check"));
  };

  const get_fact_check_view = (record) => {
    let base_url =
      record?.language_id === "839bcc29-853f-4c4c-8700-98fd88558952"
        ? "https://youturn.in/"
        : "https://en.youturn.in/";
    //   "https://youturn-sit.vertace.org/"
    // : "https://en.youturn-sit.vertace.org/";
    const url = `${base_url}factcheck/${record?.perma_link}.html`;
    window.open(url, "_blank");
  };

  const handle_delete_fact_check = (record) => {
    set_delete_modal_visible(true);
    set_selected_item(record);
    set_fact_check_modal_open(false);
  };

  const action_items = (record) => {
    return (
      <Space
        style={{
          gap: "20px",
        }}
      >
        {!(role === "Editor" && record?.status === "published") && (
          <Pressable onPress={() => handle_edit_fact_check(record)}>
            <Tooltip title="Edit" placement="top">
              <EditOutlined />
            </Tooltip>
          </Pressable>
        )}
        {!(role === "Editor" && record?.status === "published") && (
          <Pressable onPress={() => handle_delete_fact_check(record)}>
            <Tooltip title="Delete" placement="top">
              <DeleteOutlined />
            </Tooltip>
          </Pressable>
        )}

        {record?.log_count > 0 && (
          <Pressable onPress={() => handle_fact_check_history(record)}>
            <Tooltip title="History" placement="top">
              <HistoryOutlined />
            </Tooltip>
          </Pressable>
        )}
        {record?.status === "published" &&
          new Date(record.published_date_time) <= new Date() && (
            <Tooltip title="View" placement="top">
              <Pressable onPress={() => get_fact_check_view(record)}>
                <FaEye />
              </Pressable>
            </Tooltip>
          )}
      </Space>
    );
  };

  const columns = [
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Author",
      key: "post_by",
      width: "150px",
      render: (record) => {
        return record?.staff_profile?.name || record?.user_profile?.name || "-";
      },
    },
    {
      title: "Created date",
      key: "create_date",
      render: (record) => {
        return moment(record?.created_date_time).format("DD/MM/YYYY");
      },
    },
    ...(role === "Admin"
      ? [
          {
            title: "Published date",
            key: "published_date",
            render: (record) => {
              const current_date_time = moment();
              const published_date_time = moment(record?.published_date_time);
              if (
                published_date_time.isValid() &&
                published_date_time.isSameOrBefore(current_date_time)
              ) {
                return published_date_time.format("DD/MM/YYYY HH:mm:ss");
              }
              return "-";
            },
          },
        ]
      : []),
    {
      title: "Party Tags",
      key: "party_tags",
      render: (record) => {
        return record?.party_tags?.map((tag) => tag.name).join(", ") || "-";
      },
    },
    {
      title: "Anti Party Tags",
      key: "anti_party_tags",
      render: (record) => {
        return (
          record?.anti_party_tags?.map((tag) => tag.name).join(", ") || "-"
        );
      },
    },

    {
      title: t("status"),
      key: "status",
      render: (record) => {
        const current_date_time = moment();
        const published_date_time = moment(record?.published_date_time);
        if (
          published_date_time.isValid() &&
          published_date_time.isAfter(current_date_time)
        ) {
          return "Scheduled";
        }
        return get_status(record?.status);
      },
    },

    {
      title: <div style={{ textAlign: "center" }}>{t("actions")}</div>,
      dataIndex: "",
      key: "actions",
      render: (record) => action_items(record),
    },
  ];

  const fetch_data = (value, value1) => {
    set_page_number(value);
    set_page_limit(value1);
  };
  let dataSource;

  if (fact_check_type) {
    dataSource =
      fact_check_type === "user_fact_check"
        ? users_fact_check_list
        : fact_check_type === "my_fact_check"
        ? fact_check_list
        : fact_check_type === "editors_fact_check"
        ? editors_fact_check_list
        : fact_check_type === "all_fact_check"
        ? all_fact_check_list
        : "";
  } else {
    dataSource = fact_check_list;
  }

  return (
    <>
      <Table
        style={{ width: "100%" }}
        columns={columns}
        dataSource={dataSource}
        loading={
          fact_check_type === "user_fact_check"
            ? user_fact_check_loading
            : fact_check_type === "my_fact_check"
            ? fact_check_list_loading
            : fact_check_type === "editors_fact_check"
            ? editors_fact_check_loading
            : fact_check_type === "all_fact_check"
            ? all_fact_check_loading
            : ""
        }
        pagination={false}
      />
      <HStack justifyContent={"flex-end"} mt={"20px"}>
        <Pagination
          total={
            fact_check_type === "user_fact_check"
              ? user_fact_check_pagination?.total_count
              : fact_check_type === "my_fact_check"
              ? fact_check_pagination?.total_count
              : fact_check_type === "editors_fact_check"
              ? editors_fact_check_pagination?.total_count
              : fact_check_type === "all_fact_check"
              ? all_fact_check_pagination?.total_count
              : ""
          }
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetch_data}
          showTotal={(total, range) => `Total ${countFormat(total)} items`}
        />
      </HStack>
    </>
  );
};

export default AdminFactCheckList;
