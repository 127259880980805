import { Button, Pagination, Table, Typography } from "antd";
import Search from "antd/es/input/Search";
import { HStack, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import {
  navigate,
  navigateBack,
  navigateWithProps,
} from "../../../../helpers/navigator";
import {
  dynamic_request,
  get_all_fact_check_history_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import { ROUTES } from "../../../routes/my_routes";
import { countFormat } from "../../../../helpers/constants";
import { FaRegEye } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const FactCheckHistory = (props) => {
  const { fact_check_id } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);

  const back_button_state = location?.props_state?.set_back_button_click;
  const fact_check_type_location = location?.props_state?.fact_check_type;
  const back_button_default_language =
    location?.props_state?.default_language?.children;
  let current_language_id_new = location?.props_state?.default_language?.value;

  const {
    items: fact_check_history_items,
    loading: Fact_check_history_loading,
    pagination: fact_check_history_pagination,
  } = useDynamicSelector("get_all_fact_check_history");

  useEffect(() => {
    if (fact_check_id) {
      get_all_fact_check_history();
    }
  }, [fact_check_id, page_number, page_limit]);

  const get_all_fact_check_history = () => {
    let key = [{ key: "get_all_fact_check_history", loading: true }];
    let query = get_all_fact_check_history_query;
    let variables = {
      fact_check_id: fact_check_id,
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  let columns = [
    {
      title: "S.No",
      key: "s_no",
      render: (record, _, index) => {
        const current_page = page_number;
        const page_size = page_limit;
        return (current_page - 1) * page_size + index + 1;
      },
    },
    {
      title: "Edited Date",
      key: "edited_date",
      render: (record) => {
        return moment(record?.created_date_time).format(
          "DD/MM/YYYY hh:mm:ss A"
        );
      },
    },

    {
      title: (
        <div style={{ textAlign: "center" }}>{t("Fact Check History")}</div>
      ),
      key: "fact_check",
      render: (record) => (
        <div style={{ textAlign: "center" }}>
          <FaRegEye />
        </div>
      ),
    },
  ];
  const go_back = () => {
    navigateWithProps(`${ROUTES.ADMIN_FACT_CHECK}`, {
      fact_check_type_location,
      back_button_default_language,
      current_language_id_new,
      back_button_state: true,
      // set_default_language: () =>
      //   set_default_language(back_button_default_language),
    });
  };

  const handle_view_fact_check = (record) => {
    console.log("recordddddd", record);

    navigateWithProps(
      `${ROUTES.ADMIN_FACT_CHECK_HISTORY_VIEW_PAGE}/${record?.id}`,
      {
        selected_items: { record },
      }
    );
  };

  const fetch_data = (value, value1) => {
    set_page_number(value);
    set_page_limit(value1);
  };

  return (
    <VStack space={"10px"}>
      <HStack justifyContent={"flex-end"} space={"10px"}>
        <Button onClick={go_back} style={{ backgroundColor: "#d3e3fd" }}>
          Back
        </Button>
        {/* <Search
          enterButton={true}
          allowClear={true}
          placeholder="Search"
          style={{ width: "250px", backgroundColor: "white" }}
          className="admin-list-search"
        /> */}
      </HStack>

      <HStack justifyContent={"flex-start"}>
        <Typography style={{ fontWeight: "bold" }}>Title :</Typography>
      </HStack>
      <HStack justifyContent={"flex-start"}>
        <Typography>{fact_check_history_items?.[0]?.title}</Typography>
      </HStack>

      <Table
        columns={columns}
        loading={Fact_check_history_loading}
        dataSource={fact_check_history_items}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => handle_view_fact_check(record),
            className: "table-cursor",
          };
        }}
        pagination={false}
      />

      <HStack justifyContent={"flex-end"} mt={"20px"}>
        <Pagination
          total={fact_check_history_pagination?.total_count}
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetch_data}
          showTotal={(total, range) => `Total ${countFormat(total)} items`}
        />
      </HStack>
    </VStack>
  );
};

export default FactCheckHistory;
