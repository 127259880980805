import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const HistoryTitle = () => {
  const { t } = useTranslation();
  return (
    <Form.Item label={t("Title")} name="title" style={{ marginTop: "22px" }}>
      <Input disabled />
    </Form.Item>
  );
};

export default HistoryTitle;
