import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
  FileOutlined,
} from "@ant-design/icons";
// import "antd/dist/antd.min.css";
import _grid from "./grid";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Rate,
  Row,
  Select,
  Slider,
  Switch,
  Upload,
  Cascader,
  Popconfirm,
} from "antd";
import JoditEditor from "jodit-react";
import { Box, Divider, HStack, Pressable, Text } from "native-base";
import { useEffect, useId, useState } from "react";
import { startCase } from "lodash";
import lodash from "lodash";
import axios from "axios";
import { QueryRequest } from "@services/apollo/api_service";
import { gql } from "@apollo/client";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import DocumentViewer from "../../form/document_viewer";
import { GrAttachment, GrFormUpload } from "react-icons/gr";
import { AiFillDelete } from "react-icons/ai";
import { showNotification, showToast } from "@helpers/functions";
// import { toast } from "react-toastify";
import {
  change_tamil_font_style,
  getUUID,
} from "../../../../../helpers/functions";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { PiUploadSimpleFill } from "react-icons/pi";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Editor from "./editor";
import imageCompression from "browser-image-compression";

// import { useDispatch } from "react-router-dom";

// import { showToast } from "@helpers/toast";
// import TextEditor from "../quill_editor/quill_editor";
const { TextArea } = Input;
const { Option } = Select;
export const fileQuery = gql`
  query get_upload_url($type: String, $fileName: String) {
    get_upload_url(type: $type, fileName: $fileName) {
      status
      url
      error {
        status_code
        message
      }
    }
  }
`;
const validateInput = (rule, value, callback) => {
  const allowedPattern = /^[^<>]+$/;

  if (!allowedPattern.test(value)) {
    callback("Invalid characters entered.");
  } else {
    callback(); // Call the callback without any argument for a successful validation
  }
};

const TextBox = (props) => {
  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={props.rules}
      className={props.className}
    >
      <Input
        onChange={props.onChange}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        onKeyPress={props.onKeyPress}
        onPaste={props.onPaste}
        maxLength={props.max_length}
        placeholder={props.placeholder}
      />
    </Form.Item>
  );
};

const HTMLEditor = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <TextEditor />
    </Form.Item>
  );
};
const FormSwitch = (props) => {
  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={props.rules}
      valuePropName="checked"
    >
      <Switch disabled={props.disabled} />
    </Form.Item>
  );
};
const SelectSearch = (props) => {
  let options = props.options;
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <Select
        showSearch
        allowClear
        mode={props.isMulti ? "multiple" : "single"}
        onSearch={props.onSearch}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        notFoundContent={null}
        disabled={props.disabled}
      >
        {options?.map((item) => {
          if (item)
            return (
              <Option value={item[props.valueField]}>
                {item[props.labelField]}
              </Option>
            );
        })}
      </Select>
    </Form.Item>
  );
};

const Cascade = (props) => {
  return (
    <Form.Item label={props.label} name={props.field}>
      <Cascader
        options={props.options}
        loadData={props.loadData}
        onChange={props.onChange}
        changeOnSelect
        disabled={props.disabled}
      />
    </Form.Item>
  );
};
const Number = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <InputNumber
        onChange={props.onChange}
        style={{ width: "100%" }}
        controls={false}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        min={1}
        maxLength={props.max}
      />
    </Form.Item>
  );
};

const FormTextArea = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <TextArea disabled={props.disabled} />
    </Form.Item>
  );
};
const FormSelect = (props) => {
  let options = props.options;
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <Select
        mode={props.isMulti ? "multiple" : "single"}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        showSearch={props.isSearchable}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0 ||
          option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options?.map((item, index) => {
          let value = lodash.get(item, props.valueField || "id", null);
          let label = lodash.get(item, props.labelField || "name", null);
          return (
            <Option value={value} key={index}>
              {label}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};
const Password = (props) => {
  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={props.rules}
      className="user-add-input"
    >
      <Input.Password disabled={props.disabled} />
    </Form.Item>
  );
};

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
let fileUploadresponse;
const File = (props) => {
  const { t } = useTranslation();
  const { form, field, class_name } = props;
  const [fileList, setFileList] = useState([]);
  const [headers, setHeaders] = useState();
  const [file_data, setFileData] = useState("");
  const [isDocOpen, setIsDocOpen] = useState(false);
  const watchFields = Form.useWatch([field], form);
  // const dispatch = useDispatch();
  const openViewDoc = (url) => {
    setFileData(url);
    setIsDocOpen(true);
  };

  const closeViewDoc = () => {
    setFileData("");
    setIsDocOpen(false);
  };
  const handleBeforeUpload = async ({ name, type }) => {
    const hasRepeatedExtension = /\.[^.]*\.[^.]+$/.test(name);

    if (!hasRepeatedExtension) {
      const validExtensions = [".pdf", ".png"];
      const isValidExtension = validExtensions.some((ext) =>
        name.endsWith(ext)
      );
      let fileupload = {
        type: "document",
        fileName: getUUID(),
      };
      fileUploadresponse = await QueryRequest(fileQuery, fileupload);

      toast.success("Document uploaded successfully");
      // if (isValidExtension) {
      //   let fileupload = {
      //     type: "assets",
      //     fileName: name,
      //   };
      //   fileUploadresponse = await QueryRequest(fileQuery, fileupload);
      //   toast.success("Document uploaded successfully");
      // } else {
      //   toast.error("Invalid file format");
      // }
    } else {
      toast.error("Invalid file format");
    }
    // dispatch(dynamic_clear("getUploadUrl"));
  };
  const handleFileChanged = ({ file }) => {
    if (file.status === "removed") {
      setFileList([]);
    } else if (file.status === "uploading") {
      setFileList([file]);
    } else if (file.status === "done") {
      const newFile = {
        ...file,
        url: fileUploadresponse?.data?.get_upload_url?.url,
      };
      setFileList([newFile]);
    }
  };
  const handleUpload = async ({ onSuccess, onError, file }) => {
    axios
      .put(fileUploadresponse?.data?.get_upload_url?.url, file, {
        headers: { "Content-Type": file?.type },
      })
      .then(async (res) => {
        if (res.status == 200) {
          file.url =
            fileUploadresponse?.data?.get_upload_url?.url?.split("?")[0];
          onSuccess(null, file);
        } else {
          onError(err, err, file);
        }
      })
      .catch((err) => {
        onError(err, err, file);
      });
  };
  const handleDelete = () => {
    setFileList([]);
    // let val = {};
    // val[field] = [];
    // form.setFieldsValue();
  };

  const action = fileUploadresponse?.data?.get_upload_url;
  const CustomizedUploadDocuments = ({ originNode, file, fileList }) => {
    return (
      <Pressable width={"100%"} cursor={"pointer"}>
        <HStack width={"100%"} alignItems={"center"}>
          <Pressable
            width={"100%"}
            onPress={() => {
              openViewDoc(file?.url);
            }}
            cursor={"pointer"}
          >
            <HStack width={"100%"} alignItems={"center"}>
              <Box width={"10%"}>
                <GrAttachment size={20} />
              </Box>
              <Box width={"90%"}>
                <Text
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  mx={2}
                >
                  {file.name}
                </Text>
              </Box>
            </HStack>
          </Pressable>
          <Box width={"10%"}>
            <Popconfirm
              title={
                <div style={{ maxWidth: "200px" }}>{"Sure to delete?"}</div>
              }
              okText="Yes"
              cancelText="No"
              placement="leftTop"
              onConfirm={() => {
                handleDelete(file);
              }}
            >
              <AiFillDelete size={15} />
            </Popconfirm>
          </Box>
        </HStack>
      </Pressable>
    );
  };
  const uploadButton = (
    <div
      style={{
        cursor: "pointer",
        flexDirection: "row",
        justifyContent: "center",
        display: "flex",
        marginTop: "30px",
      }}
      onClick={() => {}}
    >
      <PiUploadSimpleFill
        size={20}
        style={{ color: "white", cursor: "pointer" }}
      />

      <Text color={"#fff"} mx={2} fontWeight={"bold"}>
        Update Photo
      </Text>
    </div>
  );
  const handleButtonClick = () => {
    // Trigger the click on the input element to open the file dialog
    document.getElementById("fileInput")?.click();
  };
  return (
    <>
      <Form.Item
        label={props.label}
        name={props.field}
        rules={props.rules}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        extra=""
        className={props.class_name}
      >
        <Upload
          maxCount={1}
          action={action}
          headers={headers}
          fileList={fileList || []}
          onRemove={handleDelete}
          disabled={props.isdisabled}
          customRequest={(e) => handleUpload(e)}
          beforeUpload={(args) => handleBeforeUpload(args)}
          onChange={(e) => handleFileChanged(e)}
          // itemRender={(originNode, file, fileList) => (
          //   <CustomizedUploadDocuments
          //     originNode={originNode}
          //     file={file}
          //     fileList={fileList}
          //   />
          // )}
        >
          {/* {fileList?.length >= 1 || watchFields?.length >= 1
            ? null
            : uploadButton
            } */}
          {/* {uploadButton} */}

          <Button
            icon={<UploadOutlined style={{ cursor: "pointer !important" }} />}
            // className={}
            className="your-custom-classname"
          >
            {change_tamil_font_style(t("click_to_upload"), "10px", _)}
            {/* {change_tamil_font_style(t("click_to_upload"),)} */}
          </Button>
        </Upload>
      </Form.Item>
      {/* <DocumentViewer
        fileUrl={file_data}
        title="Preview"
        isOpen={isDocOpen}
        onClose={closeViewDoc}
      /> */}
    </>
  );
};

const ImageFile = (props) => {
  const [file_list, set_file_list] = useState([]);
  const [error_message, set_error_message] = useState("");
  let file_upload_response = "";
  const action = file_upload_response?.data?.get_upload_url?.url;
  const handle_upload = ({ onSuccess, onError, file }) => {
    const newFileName = file.name.replace(/\.\w+$/, ".webp");
    let fileToUpload;
    try {
      fileToUpload = new File([file], newFileName, {
        type: "image/webp", // Assuming you want to upload as webp
      });
    } catch (error) {
      console.error("File constructor failed:", error);
      const blob = new Blob([file], { type: "image/webp" });
      fileToUpload = new Blob([blob], { type: "image/webp" });
    }
    const uploadUrl = file_list?.[0]?.url; // Ensure the correct upload URL
    axios
      .put(uploadUrl, fileToUpload, {
        headers: { "Content-Type": fileToUpload.type }, // Use the new file's type
      })
      .then((response) => {
        file.url = uploadUrl.split("?")[0];
        onSuccess(null, file);
      })
      .catch((error) => {
        onError(error);
      });
  };

  const handle_before_upload = async (file) => {
    const allowed_types = [
      "application/pdf",
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/webp",
      "image/avif",
      "image/jfif",
    ];
    if (allowed_types.includes(file.type)) {
      let fileToUpload = file; // Start with the original file
      if (file.type !== "image/webp") {
        const options = {
          maxSizeMB: 1, // Max size in MB
          maxWidthOrHeight: 1920, // Max width or height
          useWebWorker: true,
          fileType: "image/webp", // Convert to WebP
        };
        try {
          const compressedFile = await imageCompression(file, options);
          fileToUpload = compressedFile; // Use the converted file
        } catch (error) {
          console.error("Image conversion error:", error);
          showToast({
            type: "error",
            message: "Image conversion to WebP failed",
          });
          return Upload.LIST_IGNORE; // Skip upload
        }
      }
      let file_upload = {
        type: "document",
        fileName: getUUID(),
      };

      file_upload_response = await QueryRequest(fileQuery, file_upload);
      return fileToUpload; // Return the file to upload
    } else {
      console.error("Unsupported file type:", file.type);
      showToast({
        type: "error",
        message:
          "Upload Only PNG, JPG, JPEG, WEBP, AVIF, and JFIF Format Images",
      });
      return Upload.LIST_IGNORE; // Skip upload
    }
  };

  const handle_file_changed = ({ file }) => {
    if (file?.status === "removed") {
      set_file_list([]); // Clear the file list when removed
    } else if (file?.status === "uploading") {
      file = {
        ...file,
        url: file_upload_response?.data?.get_upload_url?.url,
      };
      set_file_list([file]); // Update the file list on uploading
      return file;
    } else if (file?.status === "done") {
      set_file_list([...file_list]); // Update file list on upload done
      showToast({
        type: "success",
        message: "Image uploaded successfully",
      });
      return file;
    }
  };

  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={props.rules}
      valuePropName="fileList"
      getValueFromEvent={normFile}
      extra=""
    >
      <Upload
        maxCount={props.maxCount ? props.maxCount : 1}
        action={action}
        fileList={file_list}
        disabled={props?.disabled}
        listType="picture-card"
        showUploadList={{ showDownloadIcon: false, showPreviewIcon: true }}
        customRequest={(e) => handle_upload(e)}
        beforeUpload={(args) => handle_before_upload(args)}
        onChange={(e) => handle_file_changed(e)}
      >
        {file_list?.length > 0 ? null : (
          <Button
            disabled={props?.disabled}
            style={{
              width: "100%",
              height: "100%",
            }}
            icon={<UploadOutlined />}
          >
            Upload
          </Button>
        )}
      </Upload>
    </Form.Item>
  );
};

const MonthPicker = (props) => {
  return (
    <Form.Item label={props.label} name={props.field}>
      <DatePicker
        picker="month"
        disabledDate={props.disabledDate}
        disabled={props.disabled}
      />
    </Form.Item>
  );
};
const Date = (props) => {
  return (
    <Form.Item label={props.label} name={props.field}>
      <DatePicker
        disabledDate={props.disabledDate}
        disabled={props.disabled}
        picker={props.picker}
        format={"DD/MM/YYYY"}
      />
    </Form.Item>
  );
};
const FormRadio = (props) => {
  {
    let options = props.options.map((item) => {
      return <Radio value={item["id"]}>{item["name"]}</Radio>;
    });
    return props.type === "button" ? (
      <Form.Item label={props.label} name={props.field}>
        <Radio.Group>
          {props.options.map((item) => {
            return (
              <Radio.Button value={item["id"]}>{item["name"]}</Radio.Button>
            );
          })}
        </Radio.Group>
      </Form.Item>
    ) : (
      <Form.Item label={props.label} name={props.field}>
        <Radio.Group>
          {props.direction === "row"
            ? props.options.map((item) => {
                return (
                  <Row justify="space-between">
                    <Radio value={item["id"]}>{item["name"]}</Radio>
                  </Row>
                );
              })
            : options}
        </Radio.Group>
      </Form.Item>
    );
  }
};
const CheckBox = (props) => {
  return (
    <Form.Item label={""} name={props.field} valuePropName="checked">
      <Checkbox
        onChange={props.onChange}
        style={{
          lineHeight: "32px",
        }}
        disabled={props.disabled}
        value={props.value}
      >
        {props.label}
      </Checkbox>
    </Form.Item>
  );
};
const RichText = (props) => {
  return (
    <div>
      <span>{props.label}</span>
      <Editor
        is_disabled={props.is_disabled}
        value={props.content}
        set_value={props.set_content}
      />
    </div>
    // <Form.Item label={props.label} name={props.field} rules={props.rules}>
    // </Form.Item>
  );
};
const FormButton = (props) => {
  return (
    <Form.Item
      wrapperCol={{
        span: 12,
        offset: 6,
      }}
    >
      <Button type="primary" htmlType="submit" loading={props.isLoading}>
        {props.children}
      </Button>
    </Form.Item>
  );
};
const Grid = (props) => {
  return <_grid {...props} />;
};
const List = (props) => {
  return (
    <Form.List name={props.field_name}>
      {(fields, { add, remove }) => {
        return (
          <div style={{ width: "80%", marginLeft: " 10%" }}>
            <Form.Item>
              <Button
                type="solid"
                onClick={() => add()}
                style={{ width: "20%", float: "right" }}
              >
                <PlusOutlined /> Add field
              </Button>
            </Form.Item>
            {fields.map((field, index) => (
              <div key={field.key}>
                {props.children.map((item) => {
                  let on_item = {
                    ...item,
                    props: {
                      ...item.props,
                      field: [index, `${item?.props?.field}`],
                    },
                  };
                  return on_item;
                })}
                {fields.length > 1 ? (
                  <Button
                    type="danger"
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                    icon={<MinusCircleOutlined />}
                  >
                    Remove Above Field
                  </Button>
                ) : null}
              </div>
            ))}
            <Divider />
          </div>
        );
      }}
    </Form.List>
  );
};

const AntdDynamicForm = (props) => {
  const { children } = props;
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState(props.initialValues);
  useEffect(() => {
    form.setFieldsValue(props.initialValues);
    setInitialValues(props.initialValues);
  }, [form, props.initialValues]);
  return (
    <Form
      form={props?.form}
      name={props.id}
      layout={"vertical"}
      onFinish={props.onSubmit}
      onValuesChange={props.onValueChange}
      initialValues={initialValues}
    >
      {children}
    </Form>
  );
};
AntdDynamicForm.Grid = Grid;
AntdDynamicForm.List = List;
AntdDynamicForm.TextBox = TextBox;
AntdDynamicForm.HTMLEditor = HTMLEditor;
// AntdDynamicForm.Hidden = Hidden;
AntdDynamicForm.Number = Number;
AntdDynamicForm.TextArea = FormTextArea;
AntdDynamicForm.Password = Password;
// AntdDynamicForm.IntlPhone = IntlPhone;
// AntdDynamicForm.Phone = Number;
AntdDynamicForm.Select = FormSelect;
AntdDynamicForm.Cascade = Cascade;
AntdDynamicForm.Search = SelectSearch;
AntdDynamicForm.MonthPicker = MonthPicker;
AntdDynamicForm.RichText = RichText;
AntdDynamicForm.File = File;
AntdDynamicForm.Date = Date;
AntdDynamicForm.Radio = FormRadio;
AntdDynamicForm.Switch = FormSwitch;
AntdDynamicForm.CheckBox = CheckBox;
AntdDynamicForm.Button = FormButton;
AntdDynamicForm.ImageFile = ImageFile;
export default AntdDynamicForm;
